import * as React from "react";
import Link from "next/link";
import toast from "react-hot-toast";
import Image from "next/image";
import Sample from "@public/assets/sample.png";
import Icon from "@components/icon";
import { useRouter } from "next/router";
import blurDataUrl from "@utils/shimmer";
import { useMutation } from "urql";
import {
  PinVendorService,
  UnpinVendorService,
} from "@graphql/mutations/vendor.mutation";
import {
  PinVendorServiceMutation,
  PinVendorServiceMutationVariables,
  UnPinVendorServiceMutation,
  UnPinVendorServiceMutationVariables,
} from "@graphql/types";
import useStore from "src/store";
import { motion } from "framer-motion";

type Props = {
  id: string;
  image: string;
  categoryName: string;
  pinned: boolean;
  priority: boolean;
  vendorName: string;
};

const SmallServiceCard: React.FC<Props> = (props) => {
  const [pinState, setPinState] = React.useState(props.pinned);
  const router = useRouter();
  const accessToken = useStore((state) => state.accessToken);

  const [, pin] = useMutation<
    PinVendorServiceMutation,
    PinVendorServiceMutationVariables
  >(PinVendorService);

  const [, unpin] = useMutation<
    UnPinVendorServiceMutation,
    UnPinVendorServiceMutationVariables
  >(UnpinVendorService);

  return (
    <div className="flex flex-col justify-start items-start gap-2 relative h-36 sm:h-52 md:h-72">
      {pinState ? (
        <div
          onClick={() => {
            if (!!accessToken) {
              setPinState(false);
              toast.success(
                <p className="toast">
                  You removed your interest in {props.vendorName}
                </p>
              );
              unpin({ vendorServiceId: props.id }).catch(() => {
                setPinState(true);
                toast.error(
                  <p className="toast">
                    Failed to remove interest in {props.vendorName}
                  </p>
                );
              });
            } else {
              router.push("/login");
              toast.error(
                <p className="toast">
                  You need to be logged in to show interest in{" "}
                  {props.vendorName}
                </p>
              );
            }
          }}
          className="w-fit p-2 hover:brightness-90 cursor-pointer h-fit z-[2] absolute top-0 right-0"
        >
          <Icon iconName="pinned" />
        </div>
      ) : (
        <div
          onClick={() => {
            if (!!accessToken) {
              setPinState(true);
              toast.success(
                <p className="toast">
                  Your interest in {props.vendorName} has been saved
                </p>
              );
              pin({ vendorServiceId: props.id }).catch(() => {
                setPinState(false);
                toast.error(
                  <p className="toast">Your interest wasn&apos;t saved</p>
                );
              });
            } else {
              router.push("/login");
              toast.error(
                <p className="toast">
                  You need to be logged in to show interest in{" "}
                  {props.vendorName}
                </p>
              );
            }
          }}
          className="w-fit p-2 hover:brightness-90 cursor-pointer h-fit z-[2] absolute top-0 right-0"
        >
          <Icon iconName="notPinned" />
        </div>
      )}
      <Link href={`/profile/${props.id}`}>
        <a className="w-full h-full">
          <div className="relative bg-inawo-green overflow-hidden w-full h-[80%] md:h-[90%] rounded-[10px]">
            <motion.div
              className="w-full relative flex justify-center items-center h-full"
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.05 }}
            >
              {props.image === "" ? (
                <Icon iconName="sample" />
              ) : (
                <Image
                  src={props.image}
                  placeholder="blur"
                  blurDataURL={blurDataUrl(400, 400)}
                  objectFit="cover"
                  objectPosition="center"
                  layout="fill"
                  priority={props.priority}
                  sizes="30vw"
                  quality={75}
                  alt={`${props.vendorName} ${props.categoryName} service`}
                />
              )}
            </motion.div>
          </div>
          <p className="line-clamp-1 mt-2 text-xs xs:text-sm font-sfpro font-semibold text-inawo-grey">
            {props.vendorName}
          </p>
        </a>
      </Link>
    </div>
  );
};

export default SmallServiceCard;
