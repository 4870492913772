import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { IconType } from "@utils/icon";
import Icon from "@components/icon";
import Link from "next/link";
import { motion } from "framer-motion";

type Props = {};

type StackProps = {
  name: string;
  link: string;
  iconNameInactive: Partial<IconType>;
  iconNameActive: Partial<IconType>;
};

export const Stack: React.FC<StackProps> = (props) => {
  const router = useRouter();
  const active: boolean =
    props.name.toLowerCase() === "home"
      ? router.pathname === "/"
      : router.pathname.includes(props.name.toLowerCase());
  return (
    <Link passHref href={props.link}>
      <motion.a
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.05 }}
        className="flex flex-col justify-center items-center "
      >
        <div className="h-9 w-9 flex justify-center items-center">
          {active ? (
            <Icon iconName={props.iconNameActive} />
          ) : (
            <Icon iconName={props.iconNameInactive} />
          )}
        </div>
        <p
          className={`font-sfpro text-xs xs:text-sm ${
            active
              ? "text-inawo-grey-500 font-semibold"
              : "text-inawo-black font-light"
          }`}
        >
          {props.name}
        </p>
      </motion.a>
    </Link>
  );
};

const Stacklist: StackProps[] = [
  {
    name: "Home",
    link: "/",
    iconNameInactive: "home",
    iconNameActive: "homeActive",
  },
  {
    name: "Search",
    link: "/search",
    iconNameInactive: "search",
    iconNameActive: "searchActive",
  },
  {
    name: "Interests",
    link: "/interests",
    iconNameInactive: "interest",
    iconNameActive: "interestActive",
  },
  {
    name: "Vendors",
    link: "/vendors",
    iconNameInactive: "vendors",
    iconNameActive: "vendorsActive",
  },
];

const FloatingBarHome: React.FC<Props> = () => {
  return (
    <div className="fixed bg-white w-full px-6 py-2 bottom-0 left-0 right-0 z-10 flex justify-center items-center gap-8 sm:gap-12">
      {Stacklist.map((item) => (
        <Stack
          key={uuidv4()}
          name={item.name}
          link={item.link}
          iconNameActive={item.iconNameActive}
          iconNameInactive={item.iconNameInactive}
        />
      ))}
    </div>
  );
};

export default FloatingBarHome;
